import { FunctionalComponent, h } from 'preact';
import { Route, Router } from 'preact-router';

import Home from '../routes/home';
import PaymentSuccess from '../routes/payment-success';
import NotFoundPage from '../routes/notfound';

const App: FunctionalComponent = () => {
  return (
    <div id="app">
      <Router>
        <Route path="/" component={Home} />
        <Route path="/success/" component={PaymentSuccess} />
        <NotFoundPage default />
      </Router>
    </div>
  );
};

export default App;
