
		import Async from "/vercel/path0/node_modules/@preact/async-loader/async-legacy.js";

		function load(cb) {
			require.ensure([], function (require) {
				var result = require("!!/vercel/path0/node_modules/babel-loader/lib/index.js??ref--4!/vercel/path0/src/routes/payment-success/index.tsx");
				typeof cb === 'function' && cb(result);
			});
		}

		export default Async(load);
	